import {
  useApolloClient,
  useLazyQuery,
  useMutation,
  useQuery,
} from "@apollo/client"
import React, { useEffect, useLayoutEffect, useState, useRef } from "react"
import {
  FILTER_DETAILS,
  GET_ALL_WISHLISTED_UNIVERSITIES_USER,
  STATES_CHECK,
  UNIVERSITIES_DETAILS,
  UNIVERSITIES_FEES_RANGE,
  UNIVERSITY_DETAIL_DATA,
  UPDATE_WISHLIST_UNIVERSITIES_DATA,
  UPDATE_WISHLIST_UNIVERSITIES_USER,
  USER_WISHLIST_BOOKMARK_QUERY,
} from "../components/graphql"
import SEO from "../components/seo"
import Layout from "../components/layout"
import BounceIndicator from "react-activity/lib/Bounce"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"
import $ from "jquery"
import FileSaver from "file-saver"

// import { useRef } from "react"
import Login from "../components/login"
import Select from "react-select"
// import Select from "react-dropdown-select";

const Universities_Page = universities_content => {
  const client = useApolloClient()
  const selectRef = useRef()
  const [updateUserWishlist] = useMutation(UPDATE_WISHLIST_UNIVERSITIES_USER)
  const [updateUniversityWishlist] = useMutation(
    UPDATE_WISHLIST_UNIVERSITIES_DATA
  )
  const [loader, setLoader] = useState({ id: false })
  const universitypageContent = useQuery(UNIVERSITIES_DETAILS)
  const filterContent = useQuery(FILTER_DETAILS)
  const stateCheck = useQuery(STATES_CHECK)
  const [spec, setSpec] = useState(true)
  const [notfiltered, setnotfiltered] = useState(true)
  const [select, setSelect] = useState({ value: "Recommended" })
  const [selected, setSelected] = useState([])
  const [formate, setFormate] = useState(true)
  const [duration, setDuration] = useState(true)
  const [Feerange, setFeerange] = useState(true)
  const [selected_courseFormat, setSelected_courseFormat] = useState([])
  const [selected_states, setSelected_states] = useState([])
  const [value, setValue] = useState([{ value: "id", label: "None" }])
  const [selected_courseDuration, setSelected_courseDuration] = useState([])
  const [selected_attendances, setSelected_attendances] = useState([])
  const [selected_courseName, setSelected_courseName] = useState([])
  const [att, setAtt] = useState(true)
  const [degree, setDegree] = useState(true)
  const [datasaver, setDatasaver] = useState([])
  const [sort, setSort] = useState("recommended:desc")
  const [state, setState] = useState({ checkedItems: new Map() })
  const [
    selected_courseSpecialization,
    setSelected_courseSpecialization,
  ] = useState([])
  const [location, setlocation] = useState(true)
  const [fees, setFees] = useState(true)
  const [universitiesFeesRange, { called, loading, data }] = useLazyQuery(
    UNIVERSITIES_FEES_RANGE
  )
  const [checkchange, setcheckchange] = useState(false)
  const [tutionfeecount, setTutionFeecount] = useState(false)
  const [durationcount, setDurationcount] = useState(false)
  const [formatecount, setFormatecount] = useState(false)
  const [addencecount, setAddencecount] = useState(false)
  const [speccount, setSpeccount] = useState(false)
  const [coursecount, setCoursecount] = useState(false)
  const [itemcount, setItemcount] = useState({ countValues: new Map() })
  const [datasaver2, setDatasaver2] = useState([])
  const inputFile = useRef()
  const [locationdic, setLocationdic] = useState({})
  const [feedic, setFeedic] = useState({})
  const [durationdic, setDurationdic] = useState({})
  const [formatedic, setFormatedic] = useState({})
  const [addencedic, setAddencedic] = useState({})
  const [specializationdic, setSpecializationdic] = useState({})
  const [coursedic, setCoursedic] = useState({})
  const [placeholdervalue, setPlaceholdervalue] = useState("")
  const [userID, setUserID] = useState(0)
  const [wishlisted, setWishlisted] = useState({})
  const [detail, setDetailContent] = useState()
  const [articleList, setArticleList] = useState([])
  const [forgotPassword, setForgotPassword] = useState(false)
  const [username, setUsername] = useState(undefined)
  const [phoneError, setphoneError] = useState('');
  const [successMessage,setSuccessMessage] = useState("");
  const [errorMessage,setErrorMessage] = useState("");

  const [open, setOpen] = useState(false)
  const [login, setLogin] = useState(false)
  var universityId = ""
  var wishlistCount1 = 0

  const [selectOptions, setSelectOptions] = useState({ options: "Recommended" })
  const [selectValue, setSelectValue] = useState({ value: "Recommended" })
  // var userWishlistBookmarkData="";
 const userWishlistBookmarkData = useQuery(USER_WISHLIST_BOOKMARK_QUERY, {
    variables: { id: userID },
    pollInterval: 7000,
  })
  
  React.useEffect(() => {
		if (typeof window !== `undefined`) {
			if (typeof document !== `undefined`) {
			localStorage.setItem('lastRevistedPage',window.location.pathname)
			}
		}
	});
  React.useEffect(() => {
    var str = "545"

    console.log("useEffect called")

    setUserID(localStorage.getItem("userId"))

    if (typeof window !== `undefined`) {
      if (typeof document !== `undefined`) {
        $(document).ready(function () {
          $("#humburger-menu").click(function () {
            $("#bar").toggleClass("fa-times")
            $(".menu-items ").toggle("slow")
          })
          // $(window).scroll(function() {
          //     if ($(this).scrollTop() >= 70) { // this refers to window
          //         $('.header-menu').addClass('sticky');
          //     }
          // });

          if ($(window).innerWidth() < 760) {
            $(".filter-title").click(function () {
              $(this).children("i.fas").toggleClass("fa-sort-up")
              $("#course-filters").toggle()
            })
          }

          $(".list-title").click(function () {
            $(this).siblings().toggle()
            $(this).children(".fa-sort-down").toggleClass("fa-sort-up")
          })

          $(".show-drop-down").click(function () {})
        })
      }
    }
  })

  const sort_data = sort
  useEffect(() => {
    if (selected_states.length !== 0) {
      const selected_state_ids = selected_states.length
        ? selected_states.map(s => s)
        : []
      if (selected_states.length !== 0) {
        setnotfiltered(false)
        callLazyQuery()
        setFeerange(true)
      }
    }
    if (selected.length !== 0) {
      const sort_label = value ? value.map(e => e.label).toString() : null
      const selected_ids = selected.length ? selected.map(s => s.value) : []

      if (selected.length !== 0) {
        setnotfiltered(false)
        callLazyQuery()
        setFeerange(true)
      }
    }
    if (selected_courseFormat.length !== 0) {
      const selected_courseFormat_ids = selected_courseFormat.length
        ? selected_courseFormat.map(s => s.value)
        : []

      if (selected_courseFormat.length !== 0) {
        setnotfiltered(false)
        callLazyQuery()
        setFeerange(true)
      }
    }
    if (value.length !== 0) {
      const sort_data = value ? value.map(e => e.value).toString() : null

      if (sort_data.length !== 0) {
        setnotfiltered(false)
        callLazyQuery()
        setFeerange(true)
      }
    }
    if (sort !== "recommended:desc") {
      setnotfiltered(false)
      callLazyQuery()
      setFeerange(true)
    }
    if (selected_courseDuration.length !== 0) {
      const selected_courseDuration_ids = selected_courseDuration.length
        ? selected_courseDuration.map(e => e.value)
        : []
      if (selected_courseDuration.length !== 0) {
        setnotfiltered(false)
        callLazyQuery()
        setFeerange(true)
      }
    }
    if (selected_attendances.length !== 0) {
      const selected_courseDuration_ids = selected_attendances.length
        ? selected_attendances.map(e => e.value)
        : []
      if (selected_attendances.length !== 0) {
        setnotfiltered(false)
        callLazyQuery()
        setFeerange(true)
      }
    }
    if (selected_courseSpecialization.length !== 0) {
      const selected_courseSpecialization_ids = selected_courseSpecialization.length
        ? selected_courseSpecialization.map(e => e.value)
        : []
      if (selected_courseSpecialization.length !== 0) {
        setnotfiltered(false)
        callLazyQuery()
        setFeerange(true)
      }
    }
    if (selected_courseName.length !== 0) {
      const selected_courseName_ids = selected_courseName.length
        ? selected_courseName.map(e => e.value)
        : []
      if (selected_courseName_ids.length !== 0) {
        setnotfiltered(false)
        callLazyQuery()
        setFeerange(true)
      }
    }

    if (
      selected.length === 0 &&
      selected_states.length === 0 &&
      selected_courseFormat.length === 0 &&
      selected_courseDuration.length === 0 &&
      selected_attendances.length === 0 &&
      selected_courseSpecialization.length === 0 &&
      selected_courseName.length === 0 &&
      sort === "recommended:desc"
    ) {
      datasaver.length = 0
      setDatasaver([])
      setnotfiltered(true)
      setFeerange(false)
      setcheckchange(false)
      setTutionFeecount(false)
      setDurationcount(false)
      setFormatecount(false)
      setAddencecount(false)
      setSpeccount(false)
      setCoursecount(false)
    }
  }, [
    selected,
    selected_states,
    selected_courseFormat,
    value,
    selected_courseDuration,
    selected_attendances,
    selected_courseSpecialization,
    selected_courseName,
    sort,
    state,
    login,
  ])


  if (typeof window !== "undefined") {
    if (localStorage.getItem("userId")) {
      if (localStorage.getItem("reload") === "false") {
        window.location.reload(false)
        localStorage.setItem("reload", true)
      }
    } else {
      localStorage.setItem("reload", false)
    }
  }

  const {
    allStrapiUniversitySeoBlock,
    allStrapiHomePage,
  } = universities_content.data
  const seoTitle =
    allStrapiUniversitySeoBlock !== null
      ? allStrapiUniversitySeoBlock.edges[0].node.title
      : ""
  const seoMetaDescription =
    allStrapiUniversitySeoBlock !== null
      ? allStrapiUniversitySeoBlock.edges[0].node.metaDescription
      : ""
  const seoImage =
    allStrapiHomePage !== null
      ? allStrapiHomePage.edges[0].node.metaImage
        ? allStrapiHomePage.edges[0].node.metaImage.url
        : ""
      : ""

  function callLazyQuery() {
    universitiesFeesRange({
      variables: {
        id: selected,
        stateName: selected_states,
        courseFormat: selected_courseFormat,
        courseDurations: selected_courseDuration,
        attendance: selected_attendances,
        course_specialization: selected_courseSpecialization,
        course_names: selected_courseName,
        sort: sort,
      },
    })
  }

  if (universitypageContent.loading) {
    return (
      <>
        <SEO
          title={seoTitle}
          description={seoMetaDescription}
          image={seoImage}
        />
        <div className={"centered"}>
          <BounceIndicator size={25} color={"#0055a4"} />
        </div>
      </>
    )
  }
  if (filterContent.loading) {
    return (
      <>
        <SEO
          title={seoTitle}
          description={seoMetaDescription}
          image={seoImage}
        />
        <div className={"centered"}>
          <BounceIndicator size={25} color={"#0055a4"} />
        </div>
      </>
    )
  }
  // if(!filterContent.loading){
  //   console.log("stateCheck/////////////////",stateCheck.data)
  // }
  const options = [
    // { value: "recommended:desc", label: "Recommended  " },
    { value: "universityName:asc", label: "Name Asc     " },
    { value: "universityName:desc", label: "Name Desc    " },
    // { value: "rating:desc", label: "Rating Highest" },
    // { value: "rating:asc", label: "Rating Lowest" },
    { value: "feeFirstYearAmount:desc", label: "Price Highest" },
    { value: "feeFirstYearAmount:asc", label: "Price Lowest" },
  ]

  const ResetAll = () => {
    setSelected([])
    setSelected_states([])
    setSelected_courseFormat([])
    setSelected_courseDuration([])
    setSelected_attendances([])
    setSelected_courseName([])
    setSelected_courseSpecialization([])
    setSort("recommended:desc")
    setSelect({ value: "Recommended" })
    setSelectOptions({ options: "Recommended" })
    setSelectValue({ value: "recommended:desc" })
    for (let [key, value] of state.checkedItems.entries()) {
      setState(prevState => ({
        checkedItems: prevState.checkedItems.set(key, false),
      }))
    }

    setValue([{ value: "id", label: "None" }])

    // setDatasaver([])
    datasaver.length = 0
    // console.log("datasaver", datasaver)
    callLazyQuery()
  }
  // console.log("datasaverrrrrr", datasaver)
  {
    /* if ((data !== undefined) && !notfiltered && !loading) {
    if (!datasaver.includes(data)) {
      datasaver.push(data)
    }
    console.log(datasaver)
  }

const checking = datasaver[datasaver.length - 1];*/
  }

  const handleCheckboxChange = event => {
    setcheckchange(true)
    setTutionFeecount(false)

    setTutionFeecount(false)
    setDurationcount(false)
    setFormatecount(false)
    const item = event.target.name
    const isChecked = event.target.checked
    setState(prevState => ({
      checkedItems: prevState.checkedItems.set(item, isChecked),
    }))

    if (event.target.checked) {
      let value = event.target.id

      setSelected_states(selected_states => [...selected_states, value])
    } else {
      const value1 = event.target.id
      

      setSelected_states(selected_states.filter(state => state !== value1))
    }

  }

  const handleTutionFee = event => {
    setTutionFeecount(true)
    setcheckchange(false)

    setDurationcount(false)
    setFormatecount(false)
    setAddencecount(false)
   
    const item = event.target.name
    const isChecked = event.target.checked
    setState(prevState => ({
      checkedItems: prevState.checkedItems.set(item, isChecked),
    }))
    if (event.target.checked) {
      let value = event.target.id
      setSelected(selected => [...selected, value])
    } else {
      const value1 = event.target.id
      setSelected(selected.filter(state => state !== value1))
    }
  }
  const handleCourseDuration = event => {
    setDurationcount(true)
    setcheckchange(false)
    setTutionFeecount(false)
    setAddencecount(false)
    setFormatecount(false)


    const item = event.target.name
    const isChecked = event.target.checked
    setState(prevState => ({
      checkedItems: prevState.checkedItems.set(item, isChecked),
    }))
    if (event.target.checked) {
      let value = event.target.id
      setSelected_courseDuration(selected_courseDuration => [
        ...selected_courseDuration,
        value,
      ])
    } else {
      const value1 = event.target.id
      setSelected_courseDuration(
        selected_courseDuration.filter(state => state !== value1)
      )
    }
  }
  const handleCourseFormat = event => {
    setDurationcount(false)
    setcheckchange(false)
    setTutionFeecount(false)
    setAddencecount(false)
    setFormatecount(true)
    setSpeccount(false)
    setCoursecount(false)



    const item = event.target.name
    const isChecked = event.target.checked
    setState(prevState => ({
      checkedItems: prevState.checkedItems.set(item, isChecked),
    }))
    if (event.target.checked) {
      let value = event.target.id
      setSelected_courseFormat(selected_courseFormat => [
        ...selected_courseFormat,
        value,
      ])
    } else {
      const value1 = event.target.id
      setSelected_courseFormat(
        selected_courseFormat.filter(state => state !== value1)
      )
    }
  }
  const handleAttendance = event => {
    setAddencecount(true)
    setcheckchange(false)
    setTutionFeecount(false)
    setDurationcount(false)
    setFormatecount(false)
    setSpeccount(false)
    setCoursecount(false)
    const item = event.target.name
    const isChecked = event.target.checked
    setState(prevState => ({
      checkedItems: prevState.checkedItems.set(item, isChecked),
    }))
    if (event.target.checked) {
      let value = event.target.id
      setSelected_attendances(selected_attendances => [
        ...selected_attendances,
        value,
      ])
    } else {
      const value1 = event.target.id
      setSelected_attendances(
        selected_attendances.filter(state => state !== value1)
      )
    }
  }
  const handleSpecialization = event => {
    setSpeccount(true)
    setcheckchange(false)
    setTutionFeecount(false)
    setDurationcount(false)
    setFormatecount(false)
    setAddencecount(false)
    setCoursecount(false)
    const item = event.target.name
    const isChecked = event.target.checked
    setState(prevState => ({
      checkedItems: prevState.checkedItems.set(item, isChecked),
    }))
    if (event.target.checked) {
      let value = event.target.id
      setSelected_courseSpecialization(selected_courseSpecialization => [
        ...selected_courseSpecialization,
        value,
      ])
    } else {
      const value1 = event.target.id
      setSelected_courseSpecialization(
        selected_courseSpecialization.filter(state => state !== value1)
      )
    }
  }
  const handleCourseName = event => {
    setCoursecount(true)
    setcheckchange(false)
    setTutionFeecount(false)
    setDurationcount(false)
    setFormatecount(false)
    setAddencecount(false)
    setSpeccount(false)
    const item = event.target.name
    const isChecked = event.target.checked
    setState(prevState => ({
      checkedItems: prevState.checkedItems.set(item, isChecked),
    }))
    if (event.target.checked) {
      let value = event.target.id
      setSelected_courseName(selected_courseName => [
        ...selected_courseName,
        value,
      ])
    } else {
      const value1 = event.target.id
      setSelected_courseName(
        selected_courseName.filter(state => state !== value1)
      )
    }
  }
  const wishlistRender = content => {
    userWishlistBookmarkData.data &&
      userWishlistBookmarkData.data.user &&
      userWishlistBookmarkData.data.user.wishlisted_universities.length &&
      userWishlistBookmarkData.data.user.wishlisted_universities.map(c => {
        if (c.id === content.id) {
          wishlisted[content.id] = "true"
         
        }
      })
  }

  const RenderCourseName = data =>{
    var courseNameValue=""
    if(data){
      for(var i=0;i < data.length;i++){
        if(i===0){
          courseNameValue+=data[i].courseName
        }
        else{
          courseNameValue+=","+data[i].courseName
        }
      }     
    }
    return courseNameValue
  }

  const RenderItems = ({ content }) => {
    wishlisted[content.id] = false
 
    console.log(loader, "loading is the ***************************")
    wishlistRender(content)
    var amount = content.feeFirstYearAmount ||0
    var currencySymbol = content.firstYearFeeCurrencySymbol || '€'

    console.log(content.url, "content values")


    return (
      <div className="courses-list" key={content.id}>
        <Link
          to={`/universities/${content.url}/`}
          style={{
            textDecoration: "none",
          }}
        >
          <div className="course-details">
            <div className="course-header">
              <div className="course-univ-fig">
              {content.thumbnailImage?  <img
                  src={`${process.env.BACKEND_URL}${content.thumbnailImage.url}`}
                  alt=""
                />:<img
                src={require("../assets/images/default-university-thumbnail.png")}
                alt=""
              />}
              </div>
              <div className="course-univ-details">
                <div className="univ-details">
                  <h3 className="univ-name">{content.universityName?content.universityName:""}</h3>

                  {/* <h5 className="univ-rating">{content.rating?parseFloat(content.rating).toFixed(1):0}</h5> */}
                </div>
                <div className="univ-details course">
                  <h3 className="course-name">{content.universitySubtitle?content.universitySubtitle:""}</h3>
                  <h5 className="course">
                    <span className="course-type">
                     { RenderCourseName(content.course_names)}
                    </span>
                    <span className="course-type">
                      {content.course_format.courseFormat?content.course_format.courseFormat:""}
                    </span>
                  </h5>
                </div>
                <p className="univ-location">
                  {content.stateName.stateName?content.stateName.stateName:""} {content.stateName.stateName?",":""}{" "}
                  {content.countryName.countryName?content.countryName.countryName:""}
                </p>
              </div>
            </div>
            <div className="course-body">
              <div className="details">
                <div className="course-content">
                  <h4 className="course-fees fees">
                    {currencySymbol}{amount.toLocaleString("en-IN")}
                  </h4>
                  <p className="course-year">1st Year Fee</p>
                </div>
              </div>
              <div className="details year">
                <div className="course-content">
                  <h4 className="course-fees year">
                    {content.courseDuration.value?content.courseDuration.value:""}
                  </h4>
                  <p className="course-year"> {content.courseDuration.title?content.courseDuration.title:""}</p>
                </div>
              </div>
              <div className="details">
                <div className="course-content">
                  <h4 className="course-fees date">
                    {" "}
                    {content.applicationDeadline.value?content.applicationDeadline.value:""}
                  </h4>
                  <p className="course-year">
                    {" "}
                    {content.applicationDeadline.title?content.applicationDeadline.title:""}
                  </p>
                </div>
              </div>
              <div className="details rank">
                <div className="course-content rank">
                  <h4 className="course-fees rank">
                    {" "}
                    {content.examScores.value?content.examScores.value:""}
                  </h4>
                  <p className="course-year">{content.examScores.title?content.examScores.title:""}</p>
                </div>
              </div>
            </div>
          </div>
        </Link>
        <div className="course-footer">
          {!wishlisted[content.id] && (
            <button
              type="button"
              className="btn btn-wishlist"
              onClick={e => WishlistTrigger(content, e)}
            >
              {loader[content.id] === true ? (
                <div className={"centered"} style={{ height: "14px" }}>
                  <BounceIndicator size={14} color={"#0055a4"} />
                </div>
              ) : (
                "Add to wishlist"
              )}
            </button>
          )}
         
          {wishlisted[content.id] && (
            <button
              type="button"
              className="btn btn-wishlist"
              onClick={e => UnWishlistTrigger(content)}
            >
              {loader[content.id] === true ? (
                <div className={"centered"} style={{ height: "14px" }}>
                  <BounceIndicator size={14} color={"#0055a4"} />
                </div>
              ) : (
                "Added to wishlist"
              )}
            </button>
          )}

          <button
            type="button"
            className="btn btn-brochure"
            onClick={(e) => downloadBro(e,content)}
          >
            Download Brochure
          </button>
        </div>
      </div>
    )
  }
  const downloadBro = (e,content) => {
    if (localStorage.getItem("userId")) {
      FileSaver.saveAs(
        process.env.BACKEND_URL + content.brochureValue[0].url,
        content.brochureValue[0].name
      )
    }
    else{
      e.preventDefault()
      setOpen(true)
      setLogin(true)
      setSuccessMessage("")
      setErrorMessage("")
      setphoneError("")
      localStorage.setItem("redirect", true)
      localStorage.setItem("lastPage","university")
      setLoader(prevState => ({ ...prevState, [content.id]: false }))
      return false
    }
  }


  const WishlistTrigger = (content, e) => {
    universityId = content.id //this is for blog id
    setLoader(prevState => ({ ...prevState, [content.id]: true }))

    console.log("wishlist clickdfddddddddddddddddddddddddddd")

    console.log(loader, "loader ---------------")
    if (localStorage.getItem("userId")) {
      client
        .query({
          query: UNIVERSITY_DETAIL_DATA,
          variables: { id: universityId },
        })
        .then(res => {
          wishlistCount1 = res.data.university.wishlistCount
        
        })
        .catch(err => console.log(err))
    }
    if (localStorage.getItem("userId")) {
      client
        .query({
          query: GET_ALL_WISHLISTED_UNIVERSITIES_USER,
          variables: { id: userID },
        })
        .then(res => {
          console.log(articleList.length)
          console.log("articleList first=====", articleList)
          if (res.data.user) {
            if (res.data.user.wishlisted_universities.length > 0) {
              res.data.user.wishlisted_universities.map(record => {
                articleList.push(record.id)
              })
            }
            if (!res.data.user.wishlisted_universities.includes(universityId)) {
             
              articleList.push(universityId)
            }
            updateUserWishlist({
              variables: {
                id: userID,
                wishlistID: articleList,
              },
            }).then(response => {
              console.log(response)
              var count = 0
              if (wishlistCount1) {
                count = wishlistCount1
              }
              count = count + 1
              updateUniversityWishlist({
                variables: {
                  id: universityId,
                  wishlistCount: count,
                },
              })
                .then(response => {
                  console.log("response", response)
                  console.log("articleList last=====", articleList)
                })
                .finally(e => {
                  wishlisted[universityId] = true
                  setLoader(prevState => ({
                    ...prevState,
                    [content.id]: false,
                  }))
                  console.log(loader, "loader -------------///////////--")
                })
            })
          }
        })
    } else {
      console.log("logouttttttttttttttttttttttttt")
      e.preventDefault()
      setOpen(true)
      setLogin(true)
      setSuccessMessage("")
      setErrorMessage("")
      setphoneError("")
      localStorage.setItem("redirect", true)
      localStorage.setItem("lastPage","university")
      setLoader(prevState => ({ ...prevState, [content.id]: false }))
      return false
    }
  }
  console.log("wishlisted[universityId]=====", wishlisted)
  
  const UnWishlistTrigger = content => {
    setLoader(prevState => ({ ...prevState, [content.id]: true }))
    client
      .query({
        query: GET_ALL_WISHLISTED_UNIVERSITIES_USER,
        variables: { id: userID },
      })
      .then(res => {
        if (res.data.user) {
          var articleList = []

          if (res.data.user.wishlisted_universities.length > 0) {
            res.data.user.wishlisted_universities.map(record => {
              if (content.id !== record.id) {
                articleList.push(record.id)
              }
            })
          }

          updateUserWishlist({
            variables: {
              id: userID,
              wishlistID: articleList,
            },
          }).then(response => {
            var count = 0
            if (content.wishlistCount) {
              count = content.wishlistCount
            }
            count = count - 1
            updateUniversityWishlist({
              variables: {
                id: content.id,
                wishlistCount: count,
              },
            })
              .then(response => {
                console.log("response", response)
              })
              .finally(e => {
                wishlisted[content.id] = false
                setLoader(prevState => ({ ...prevState, [content.id]: false }))
              })
          })
        }
      })
  }

  const selectHandleChange = val => {
    console.log("val====================>>>>>>>>>>>", val)
    setSelect({ value: val.label })
    setSelectOptions({ options: val.label })
    setSelectValue({ value: val.value })

    setPlaceholdervalue(val.label)
    setSort(val.value)
  }

  console.log("select.value------------------>", select.value)
  //location===========
  const Dict = ({ data, e, dictionary }) => {
    var count = data.universities.filter(
      (content, index) => content.stateName.id === e.id
    ).length

    dictionary[e.id] = count
 
    return count
  }

  const Dict2 = ({ data, e, dictionary }) => {
    return dictionary[e.id]
  }

  //feees==================
  const Dictfee = ({ data, e }) => {
    console.log(data, "data ///////////////////>>>>>>>>>>>>>>>>>>>>>>", e)

    var count = 0
    data.universities.map((content, index) =>
      content.tution_fee_ranges.map(p => (p.id === e.id ? (count += 1) : null))
    )

    feedic[e.id] = count

    return count
  }

  const Dict2fee = ({ data, e }) => {
    return feedic[e.id]
  }
  //course duration=============
  const Dictduration = ({ data, e }) => {
    var count = data.universities.filter(
      (content, index) => content.course_duration.id === e.id
    ).length

    durationdic[e.id] = count

    return count
  }

  const Dict2duration = ({ data, e }) => {
    return durationdic[e.id]
  }

  //course formate=============
  const Dictformate = ({ data, e }) => {
    var count = data.universities.filter(
      (content, index) => content.course_format.id === e.id
    ).length
 
    formatedic[e.id] = count

    return count
  }

  const Dict2formate = ({ data, e }) => {
    return formatedic[e.id]
  }

  //course addence=============
  const Dictaddence = ({ data, e }) => {
    var count = data.universities.filter(
      (content, index) => content?.attendance?.id === e.id
    ).length

    addencedic[e.id] = count
 
    return count
  }

  const Dict2addence = ({ data, e }) => {
    return addencedic[e.id]
  }

  //course specialization=========
  const Dictspecialization = ({ data, e }) => {
    var count = data.universities.filter(
      (content, index) => content.course_specialization.id === e.id
    ).length

    specializationdic[e.id] = count
 
    return count
  }

  const Dict2specialization = ({ data, e }) => {
    return specializationdic[e.id]
  }

  //course courseName===========
  const Dictcourse = ({ data, e }) => {
    var count = data.universities.filter(
      (content, index) => content.course_names.id === e.id
    ).length

    coursedic[e.id] = count

    return count
  }

  const Dict2course = ({ data, e }) => {
    return coursedic[e.id]
  }

  const onMenuOpen = () => {
    setTimeout(() => {
      const { focusedOptionRef } = selectRef.current.select
      console.log(selectRef.current.select)
      focusedOptionRef &&
        focusedOptionRef.scrollIntoView({ behavior: "smooth" })
    }, 1)
  }

  return (
    <>
      <SEO title={seoTitle} description={seoMetaDescription} image={seoImage} />
      <div className="wrapper homepage">
        <Layout setTitle={"Universities"} tag="universities">
          <Login
            open={open}
            setOpen={setOpen}
            login={login}
            setLogin={setLogin}
            setUsername={setUsername}
            setUserID={setUserID}
            forgotPassword={forgotPassword}
            setForgotPassword={setForgotPassword}
            errorMessage ={errorMessage}
            setErrorMessage ={setErrorMessage}
            successMessage ={successMessage}
            setSuccessMessage ={setSuccessMessage}
            phoneError ={phoneError}
            setphoneError ={setphoneError}
          />
          <main className="user">
            <div className="main-content">
              <div className="container">
                <div className="existing-user Filter-parent">
                  <div className="user-content Filter">
                    <div className="filter-content">
                      <div className="filters">
                        {/*<!-- filters heading -->*/}
                        <div className="filters-heading">
                          <h5 className="filter-title">
                            Filters{" "}
                            <i
                              id="mobile-open-filter"
                              class="fas fa-sort-down"
                            ></i>
                          </h5>
                          <button
                            className="btn btn-reset-all"
                            onClick={ResetAll}
                          >
                            <i class="fas fa-redo-alt"></i> reset all
                          </button>
                        </div>
                        <ul id="course-filters" className="list list-unstyled">



                          
                        <li className="filter">
                        <a
                          href="#"
                          className="list-title"
                          onClick={e => {
                            e.preventDefault()
                            setDegree(!degree)
                          }}
                        >
                          Degree type
                          {degree && <i class="fas fa-sort-down"></i>}
                          {!degree && <i class="fas fa-sort-up"></i>}
                        </a>
                        {degree && filterContent.data ? (
                          <div className="form-groups">
                            {filterContent.data.courses.map(e => (
                              <div className="form-group" key={e.id}>
                                <input
                                  type="checkbox"
                                  id={e.id}
                                  className="input-radio"
                                  name={e.id}
                                  checked={state.checkedItems.get(e.id)}
                                  onClick={e => handleCourseName(e)}
                                />
                                <label htmlFor={e.id}>{e.courseName}</label>
                              </div>
                            ))}
                          </div>
                        ) : null}
                      </li>

                      <li className="filter">
                        <a
                          href="#"
                          className="list-title"
                          onClick={e => {
                            e.preventDefault()
                            setSpec(!spec)
                          }}
                        >
                          Course Specialization{" "}
                          {spec && <i class="fas fa-sort-down"></i>}
                          {!spec && <i class="fas fa-sort-up"></i>}
                        </a>
                        {spec && filterContent.data ? (
                          <div className="form-groups">
                            {filterContent.data.courseSpecializations.map(
                              e => (
                                <div className="form-group" key={e.id}>
                                  <input
                                    type="checkbox"
                                    id={e.id}
                                    className="input-radio"
                                    name={e.id}
                                    checked={state.checkedItems.get(e.id)}
                                    onClick={e => handleSpecialization(e)}
                                  />
                                  <label htmlFor={e.id}>{e.type}</label>
                                  {/* <p className="items-count">
                                    {!speccount && data && (
                                      <Dictspecialization
                                        data={data}
                                        e={e}
                                      />
                                    )}
                                    {speccount && data && (
                                      <Dict2specialization
                                        data={data}
                                        e={e}
                                      />
                                    )}
                                  </p> */}
                                </div>
                              )
                            )}
                          </div>
                        ) : null}
                      </li>
                          <li className="filter">
                            <a
                              href="#"
                              className="list-title"
                              onClick={() => setlocation(!location)}
                            >
                              Location
                              {location && (
                                <i
                                  class="fas fa-sort-up"
                                  style={{ display: "none" }}
                                ></i>
                              )}
                              {location && <i class="fas fa-sort-down"></i>}
                              {!location && <i class="fas fa-sort-up"></i>}
                            </a>

                            {location && filterContent.data ? (
                              <div className="form-groups">
                                {filterContent.data.states.map(e => (
                                  <div className="form-group" key={e.id}>
                                    <input
                                      type="checkbox"
                                      name={e.stateName}
                                      checked={state.checkedItems.get(
                                        e.stateName
                                      )}
                                      onClick={e => handleCheckboxChange(e)}
                                      id={e.id}
                                      className="input-radio"
                                    />
                                    <label htmlFor={e.id}>{e.stateName}</label>

                                    {/* <p className="items-count">
                                      {!checkchange && data && (
                                        <Dict
                                          data={data}
                                          e={e}
                                          dictionary={locationdic}
                                        />
                                      )}
                                      {checkchange && data && (
                                        <Dict2
                                          data={data}
                                          e={e}
                                          dictionary={locationdic}
                                        />
                                      )}
                                    </p> */}
                                  </div>
                                ))}
                              </div>
                            ) : null}
                          </li>
                          <li className="filter">
                            <a
                              href="#"
                              className="list-title"
                              onClick={e => {
                                e.preventDefault()
                                setFees(!fees)
                              }}
                            >
                              {/*fees && console.log(filterContent)*/}
                              Tuition Fees
                              {!fees && <i class="fas fa-sort-up"></i>}
                              {fees && <i class="fas fa-sort-down"></i>}
                            </a>
                            {fees && filterContent.data ? (
                              <div className="form-groups">
                                {filterContent.data.tutionFees.map(e => (
                                  <div className="form-group" key={e.id}>
                                    <input
                                      type="checkbox"
                                      id={e.id}
                                      className="input-radio"
                                      name={e.range}
                                      checked={state.checkedItems.get(e.range)}
                                      onClick={e => handleTutionFee(e)}
                                    />
                                    <label htmlFor={e.id}>{e.range}</label>
                                    {/* <p className="items-count">
                                      {!tutionfeecount && data && (
                                        <Dictfee data={data} e={e} />
                                      )}
                                      {tutionfeecount && data && (
                                        <Dict2fee data={data} e={e} />
                                      )}
                                    </p> */}
                                  </div>
                                ))}
                              </div>
                            ) : null}
                          </li>
                          {/*<li className="filter">
                      <a href="javascript:;" className="list-title"
                        >Exams Accepted <i class="fas fa-sort-down"></i
                      ></a>
                      <div className="form-groups">
                        <div className="form-group">
                          <input type="checkbox" id="GRE" className="input-radio" />
                          <label for="GRE">GRE</label>
                          <p className="items-count">12</p>
                        </div>
                        <div className="form-group">
                          <input
                            type="checkbox"
                            id="GMAT"
                            className="input-radio"
                          />
                          <label for="GMAT">GMAT</label>
                          <p className="items-count">12</p>
                        </div>
                        <div className="form-group">
                          <input
                            type="checkbox"
                            id="IELTS"
                            className="input-radio"
                          />
                          <label for="IELTS">IELTS</label>
                          <p className="items-count">12</p>
                        </div>
                        <div className="form-group">
                          <input
                            type="checkbox"
                            id="DELF"
                            className="input-radio"
                          />
                          <label for="DELF">DELF/DALF</label>
                          <p className="items-count">12</p>
                        </div>
                      </div>
                      </li>*/}
                          <li className="filter">
                            <a
                              href="#"
                              className="list-title"
                              onClick={e => {
                                e.preventDefault()
                                setDuration(!duration)
                              }}
                            >
                              Course Duration
                              {duration && <i class="fas fa-sort-down"></i>}
                              {!duration && <i class="fas fa-sort-up"></i>}
                            </a>
                            {duration && filterContent.data ? (
                              <div className="form-groups">
                                {filterContent.data.courseDurations.map(e => (
                                  <div className="form-group" key={e.id}>
                                    <input
                                      type="checkbox"
                                      id={e.id}
                                      className="input-radio"
                                      name={e.duration}
                                      checked={state.checkedItems.get(
                                        e.duration
                                      )}
                                      onClick={e => handleCourseDuration(e)}
                                    />
                                    <label htmlFor={e.id}>{e.duration}</label>
                                    {/* <p className="items-count">
                                      {!durationcount && data && (
                                        <Dictduration data={data} e={e} />
                                      )}
                                      {durationcount && data && (
                                        <Dict2duration data={data} e={e} />
                                      )}
                                    </p> */}
                                  </div>
                                ))}
                              </div>
                            ) : null}
                          </li>
                          <li className="filter">
                            <a
                              href="#"
                              className="list-title"
                              onClick={e => {
                                e.preventDefault()
                                setFormate(!formate)
                              }}
                            >
                              Format
                              {formate && <i class="fas fa-sort-down"></i>}
                              {!formate && <i class="fas fa-sort-up"></i>}
                            </a>
                            {formate && filterContent.data ? (
                              <div className="form-groups">
                                {filterContent.data.courseTimes.map(e => (
                                  <div className="form-group" key={e.id}>
                                    <input
                                      type="checkbox"
                                      id={e.id}
                                      className="input-radio"
                                      name={e.courseFormat}
                                      checked={state.checkedItems.get(
                                        e.courseFormat
                                      )}
                                      onClick={e => handleCourseFormat(e)}
                                    />
                                    <label htmlFor={e.id}>
                                      {e.courseFormat}
                                    </label>
                                    {/* <p className="items-count">
                                      {!formatecount && data && (
                                        <Dictformate data={data} e={e} />
                                      )}
                                      {formatecount && data && (
                                        <Dict2formate data={data} e={e} />
                                      )}
                                    </p> */}
                                  </div>
                                ))}
                              </div>
                            ) : null}
                          </li>
                          <li className="filter">
                            <a
                              href="#"
                              className="list-title"
                              onClick={e => {
                                e.preventDefault()
                                setAtt(!att)
                              }}
                            >
                              Attendance
                              {att && <i class="fas fa-sort-down"></i>}
                              {!att && <i class="fas fa-sort-up"></i>}
                            </a>
                            {att && filterContent.data ? (
                              <div className="form-groups">
                                {filterContent.data.attendances.map(e => (
                                  <div className="form-group" key={e.id}>
                                    <input
                                      type="checkbox"
                                      id={e.id}
                                      className="input-radio"
                                      name={e.type}
                                      checked={state.checkedItems.get(e.type)}
                                      onClick={e => handleAttendance(e)}
                                    />
                                    <label htmlFor={e.id}>{e.type}</label>
                                    {/* <p className="items-count">
                                      {!addencecount && data && (
                                        <Dictaddence data={data} e={e} />
                                      )}
                                      {addencecount && data && (
                                        <Dict2addence data={data} e={e} />
                                      )}
                                    </p> */}
                                  </div>
                                ))}
                              </div>
                            ) : null}
                          </li>
  



                          {/*    <li className="filter">
                            <a className="list-title">
                              More Options <i class="fas fa-sort-up"></i>
                            </a>
                                      </li>*/}
                        </ul>
                      </div>
                      <div className="filters-results">
                        {loading && (
                          <div className={"centered"}>
                            <BounceIndicator size={25} color={"#0055a4"} />
                          </div>
                        )}
                        <div className="filter-course-result">
                          {!loading && Feerange && data && (
                            <h1 className="course-counts">
                              Showing {data.universities.length} master courses
                            </h1>
                          )}
                          {notfiltered && universitypageContent && (
                            <h1 className="course-counts">
                              Showing{" "}
                              {universitypageContent.data
                                ? universitypageContent.data.universities.length
                                : 0}{" "}
                              master courses
                            </h1>
                          )}

                          {!loading && (
                            <div className="course-sort">
                              {/*<form className="sort-by">
                            <label>sort by :</label>
                            <select className="sort-by">
                            <option className="show-drop-down" value="grapefruit">Recommended</option>
                            <option className="show-drop-down" value="lime">University Name</option>
                            <option className="show-drop-down" value="coconut">Rating Lowest</option>
                            <option className="show-drop-down" value="mango">Rating Highest</option>
                            
                            </select>
                          </form>*/}

                              <h5 className="sort-by">sort by :</h5>
                              <Select
                                options={options}
                                value={{
                                  value: selectValue.value,
                                  label: selectOptions.options,
                                }}
                                onChange={val => selectHandleChange(val)}
                              />
                              {/*        <select
                                  ref={inputFile}
                                  value={select.value}
                                  onChange={val => selectHandleChange(val)}
                                  style={{border:"none",outline:"none",width:"200px"}}
                                  className="sort-by"
                                >
                                  <option
                                    className="show-drop-down"
                                    value="recommended:desc"
                                  >  
                                   Recommended
                                  </option>
                                  <option
                                    className="show-drop-down"
                                    value="universityName:asc"
                                  >
                                  Name Asc 
                                  </option>
                                  <option
                                  className="show-drop-down"
                                  value="universityName:desc"
                                >
                                Name Desc 
                                </option>
                                  <option
                                    className="show-drop-down"
                                    value="rating:desc"
                                  >
                                    Rating Highest
                                  </option>
                                  <option
                                    className="show-drop-down"
                                    value="rating:asc"
                                  >
                                    Rating Lowest
                                  </option>
                                  <option
                                  className="show-drop-down"
                                  value="feeFirstYearAmount:desc"
                                >
                                Price Highest
                                </option>
                                <option
                                className="show-drop-down"
                                value="feeFirstYearAmount:asc"
                              >
                              Price Lowest
                              </option>

                                
                  </select>*/}

                              {/*  <h5 className="sort-by">
                                sort by :
                                <Select
                                color="#3377b6"
                                placeholder={(sort === "recommended:desc") ? "Recommended    " : placeholdervalue}
                                   placeholder="Recommended    "
                                  style={{
                                    position: "relative",
                                    textDecoration: "none",
                                    color: "#3377b6",
                                    width: "400",
                                    border: "none",
                                    fontSize: "16",
                                    display: "flex",
                                    minWidth: "226"

                                  }}
                                   className="show-drop-down "
                                  options={options}
                                onChange={(value) => selectHandleChange(value)} />*/}

                              {/*    </h5>*/}
                              {/* sort by:<Select
                      
                         options={options}
                            values={[]}
              
                           onChange={(value) =>selectHandleChange(value)} 
                        />*/}
                            </div>
                          )}
                        </div>
                        {/*///////////////////////////////////////////////////*/}
                        {/*   <Select
                      
                        options={options}


                     onChange={(value) => selectHandleChange(value)} />*/}

                        {!loading &&
                        Feerange &&
                        data &&
                        data.universities.length === 0 ? (
                          <h3 style={{ marginTop: "25vh", marginLeft: "25vw" }}>
                            No data to display
                          </h3>
                        ) : null}
                        {!loading && Feerange && data
                          ? data.universities.map((content, index) => (
                              <RenderItems content={content} />
                            ))
                          : console.log("fiters not excuted")}

                        {notfiltered && universitypageContent.data
                          ? universitypageContent.data.universities.map(
                              (content, index) => (
                                <RenderItems content={content} />
                              )
                            )
                          : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </Layout>
      </div>
    </>
  )
}

export default Universities_Page

export const query = graphql`
  query {
    allStrapiUniversitySeoBlock {
      edges {
        node {
          title
          metaDescription
        }
      }
    }
    allStrapiHomePage {
      edges {
        node {
          metaImage {
            url
          }
        }
      }
    }
  }
`
